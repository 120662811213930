export const responsiveItemsBreakpoints = {
  tablet: {
    breakpoint: { max: 991, min: 760 },
    items: 4,
    slidesToSlide: 4,
  },
  lgMobile: {
    breakpoint: { max: 760, min: 464 },
    items: 3,
    slidesToSlide: 3,
  },
  smMobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
}

export const responsiveTestimonialsBreakpoints = {
  lgDesktop: {
    breakpoint: { max: 5000, min: 1701 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 1700, min: 1201 },
    items: 3,
    slidesToSlide: 3,
  },
  lgMobile: {
    breakpoint: { max: 1200, min: 485 },
    items: 2,
    slidesToSlide: 2,
  },
  smMobile: {
    breakpoint: { max: 484, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
